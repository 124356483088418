import React, { useEffect } from "react"
import { useTranslation } from "react-i18next"

import { itunesLink, googlePlayLink } from "../constants/links"

/* Components */
import SEO from "../components/seo/seo"
import Wrapper from "../components/wrapper/wrapper"
import HeroSimple from "../components/hero-simple/hero-simple"
import Container from "../components/container/container"
import Header from "../components/header/header"
import Loading from "../components/loading/loading"
import Footer from "../components/footer/footer"

export default function App({ location, data }) {
  const { t } = useTranslation()

  const PageTitle = t("pages.app.title")
  const PageDescription = t("meta.description")

  useEffect(() => {
    var TIMEOUT = 1 * 1000 // 1 second
    var ua = navigator.userAgent.toLowerCase()
    var params = window.location.search
    var defaultLink = "https://www.pumafastpay.com"
    function redirect(target) {
      setTimeout(function () {
        window.location = target
      }, TIMEOUT)
    }
    if (ua.indexOf("android") > -1) {
      // If params = concact
      if (params) {
        var newAndroidLink = googlePlayLink.concat(params.replace("?", "&"))
        redirect(newAndroidLink)
      } else {
        redirect(googlePlayLink)
      }
    } else if (ua.indexOf("iphone") > -1 || ua.indexOf("ipad") > -1) {
      // If params = concact & replace ?
      if (params) {
        var newIosLink = itunesLink.concat(params.replace("?", "&"))
        redirect(newIosLink)
      } else {
        redirect(itunesLink)
      }
    } else {
      redirect(defaultLink)
    }
  }, [])

  return (
    <Wrapper>
      <SEO title={PageTitle} description={PageDescription} />
      <HeroSimple>
        <Container>
          <Header classVariant="headerSimple" location={location} />
        </Container>
      </HeroSimple>
      <Loading />
      <Footer />
    </Wrapper>
  )
}
